import React, { memo } from 'react';
import styled from 'styled-components';
import Seo from '../components/Seo';
// Components
import Layout from '../components/Layout';
//import PageHero from "../components/PageHero/PageHero"

import ScrollButton from '../components/ScrollButton';
import { graphql } from 'gatsby';
import { getImage } from 'gatsby-plugin-image';
import { convertToBgImage } from 'gbimage-bridge';
import BackgroundImage from 'gatsby-background-image';

const ContentWrapper = styled.div`
  display: block;

  @media (min-width: 992px) {
    display: flex;
  }
`;

const PageContent = styled.article`
  margin-top: 20px;
`;

const PageTemplate = memo(({ data }) => {
  const featuredimage = getImage(data.wpPage.featuredImage.node.gatsbyImage);
  const bgImage = convertToBgImage(featuredimage);

  return (
    <BackgroundImage
      // Spread bgImage into BackgroundImage:
      {...bgImage}
      preserveStackingContext
      className="masthead3"
    >
      <Layout fullMenu>
        <Seo title={data.wpPage.title} description={data.wpPage.excerpt} />
        <ScrollButton />
        <article id="blogpost">
          <header>
            <h2 dangerouslySetInnerHTML={{ __html: data.wpPage.title }} />
            <p>Surge Consulting</p>
          </header>

          <section className="wrapper style2">
            <div className="inner">
              <ContentWrapper>
                <PageContent>
                  <div
                    dangerouslySetInnerHTML={{ __html: data.wpPage.content }}
                  />
                </PageContent>
              </ContentWrapper>
            </div>
          </section>
        </article>
      </Layout>
    </BackgroundImage>
  );
});

export default PageTemplate;

export const pageQuery = graphql`
  query ($id: String!) {
    wpPage(id: { eq: $id }) {
      id
      title
      content
      excerpt
      status
      featuredImage {
        node {
          gatsbyImage(
            width: 1000
            placeholder: BLURRED
            quality: 90
            formats: AUTO
          )
        }
      }
      wpChildren {
        nodes {
          ... on WpPage {
            id
            title
            content
            status
            featuredImage {
              node {
                gatsbyImage(
                  width: 1000
                  placeholder: DOMINANT_COLOR
                  quality: 90
                  formats: AUTO
                )
              }
            }
          }
        }
      }
      wpParent {
        node {
          ... on WpPage {
            id
            uri
            title
            wpChildren {
              nodes {
                ... on WpPage {
                  id
                  title
                  uri
                }
              }
            }
          }
        }
      }
    }
    backgroundImage123: file(relativePath: { eq: "library.jpg" }) {
      childImageSharp {
        gatsbyImageData(
          quality: 100
          webpOptions: { quality: 100 }
          height: 1200
        )
      }
    }
    allWpCategory {
      nodes {
        name
        id
        uri
        slug
      }
    }
  }
`;
